import React from 'react';

import {
	BrowserRouter as Router,
	Route,
	Switch,
} from "react-router-dom";



import Zone300TV from './Zone300TV';
import Details from './Detail';
import Player from './MyPlayerHls';

class Browse extends React.Component{
	constructor(){
		super()
		this.state = {
			device: ''
		}
	}
	componentWillMount(){	
//		this.setState({device : localStorage.getItem("device")});
//		console.log("this.state : ", this.state, " / ", localStorage.getItem("device"));
	}

	render(){
		const monPlayer = Player
		return(
				<Router>
					<Switch>
						<Route path="/" exact component={Zone300TV} parent={this}/>
						<Route path="/detail/:FilmID/:CatID?" component={Details} />
						<Route path="/lecture/:FilmID" component={monPlayer} />
					</Switch>
				</Router>
		)
	}
}

export default Browse;

