import React from 'react';
import Hls from 'hls.js';

import Shortcut from '../global/Shortcut';

class MyPlayer extends React.Component{
    constructor(){
        super();

        this.state = {
			isLoaded: false,
			film : '',
			synopsis: '',
			myPlayer: null,
			keyCode: ''
		};
		this.hls = new Hls({ enableWorker: false, debug: false, maxBufferSize: 5, maxBufferLength: 20, liveBackBufferLength: 0 });

		this.newShortcuts = [
			// Basé sur la zapette de la firestick tv
			{
				keyCode: 39, // Right arrow
				handle: (player, actions) => {
					player.currentTime = ( player.currentTime + 5 );
					/*
					actions.forward(5, {
						action: 'forward-5',
						source: 'shortcut'
					}); // Go forward 5 seconds
					*/
				}
			},
/*			{
				keyCode: 38, // Commande Hisense
				handle: (player, actions) => {
					actions.forward(10, {
						action: 'forward-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
				}
			},*/
			{
				keyCode: 228, // l
				handle: (player, actions) => {
					player.currentTime = ( player.currentTime + 10 );
					/*
					actions.forward(10, {
						action: 'forward-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
					*/
				}
			},
			{
				keyCode: 37, // Left arrow
				handle: (player, actions) => {
					player.currentTime = ( player.currentTime - 5);
					/*
					actions.replay(5, {
						action: 'replay-5',
						source: 'shortcut'
					}); // Go forward 5 seconds
					*/
				}
			},
			{
				keyCode: 227, // l
				handle: (player, actions) => {
					player.currentTime = ( player.currentTime - 10 );
					/*
					actions.replay(10, {
						action: 'replay-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
					*/
				}
			},
			{
				keyCode: 179,
				handle: (player, actions) => {
					if (player.paused) {
						player.play();
					} else {
						player.pause();
					}
				}
			},
			{
				keyCode: 13,
				handle: (player, actions) => {
					if (player.paused) {
						player.play();
					} else {
						player.pause();
					}
				}
			}
		];


	}

	componentWillUnmount(){
		this.hls.destroy();
	}
    componentDidMount(){
		//console.log('Player componentDidMount ');
		var leFilm = localStorage.getItem("Film")
		//console.log('Player this.state.film : ',this.state.film);
		//console.log('Player leFilm : ', leFilm);

		var video = this.player;
		this.setState({myPlayer: this.player});

        if(video.canPlayType('application/vnd.apple.mpegurl')){
			// Ce nativageur lit nativement le HLS
			console.log('LECTURE en natif');
			video.src = leFilm;
			video.addEventListener("loadedmetadata", function() {
				video.play();
			});
        }else if (Hls.isSupported()) {
			console.log('LECTURE en NON natif');
			/*
			var hls = new Hls({
				// This configuration is required to insure that only the
				// viewer can access the content by sending a session cookie
				// to api.video service
				xhrSetup: function(xhr, url) {
					xhr.withCredentials = true;
				}
			});
			*/
			this.hls.attachMedia(video);
			this.hls.on(Hls.Events.MEDIA_ATTACHED, () =>{
				this.hls.loadSource(leFilm);

				this.hls.on(Hls.Events.MANIFEST_PARSED, () =>{
					video.play();
				})
			})


			/*
			this.hls.loadSource(this.state.film);
			this.hls.on(Hls.Events.MANIFEST_PARSED, function() {
				video.play();
			});
			*/

		} else {
			alert("Please use a modern browser to play the video");
		}
		localStorage.removeItem("Film");
        localStorage.removeItem("FilmVideo");
		localStorage.removeItem("FilmHls");
		
		if(!leFilm){
			window.history.go(-1);
		}

    }

    _onTouchInsidePlayer(){
        if (this.player.paused) {
            this.player.play();
        }else{
            this.player.pause();
        }
    }

    render(){
		return (
			<div 
				style={{position:"relative",width:window.innerWidth, height:window.innerHeight }}
				ref={(ref) => this.mainPlayer = ref}
				onKeyDown={(key) => { this.setState({keyCode:key.keyCode}); console.log("key : ",key ) }}
			>
				<video 
					controls
					onClick={this.onTouchInsidePlayer}
					ref={ (player) => { this.player = player} }
					autoPlay={true}
					width={window.innerWidth}
					height={window.innerHeight}
				/>
				<Shortcut key="shortcut" player={this.state.myPlayer} shortcuts={this.newShortcuts} />
			</div>
		)
    }
}

export default MyPlayer;