
import React from 'react';

import List from './List.new';
import axios from 'axios';

//import VideoPlayer from '@cybergab/gab-react-video-js-player';
import { VerticalList, Focusable } from 'react-key-navigation'

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import Carousel from './Carousel.new';

import './Zone300TV.css';
import './Chasse.css'; 

const mData = {
	is_Guest:'',
	NoCli:'',
}


class Peche extends React.Component {
	constructor(){
		super()
		this.state = {
			isLoged: false,
			active: null,
			Categ: 'peche',
			isLoaded: false,
		}
		this.vCateg = [];
		this.vFilm = [];
//		this.lists = ["Title 1", "Title 2", "Title 3", "Title 4"];
		this.carouselOnBlur = this.carouselOnBlur.bind(this)
		this.carouselOnFocus = this.carouselOnFocus.bind(this)
		this.changeFocusTo = this.changeFocusTo.bind(this);
		this.onFocusVertical = this.onFocusVertical.bind(this);

	}
	onFocusVertical(index,item, ess){
		//console.log("onFocusVertical => index : ",index," item : ",item, " ess : ", ess)
	}
	changeFocusTo(index) {
		this.setState({active: index});
	  }
	
	onBlurLists() {
		this.setState({active: null});
	}

	carouselOnBlur(){
		this.setState({carouselActive: false});
		if(this.carousel) this.carousel.refs.MyCar1.carouselWrapperRef.blur()
	}
	carouselOnFocus(e,e1){
		this.setState({carouselActive: true});
		if(this.carousel){
			if(!this.carousel.refs.MyCar1){
				setTimeout(() => {this.carousel.refs.MyCar1.carouselWrapperRef.focus()},500);
			}else{
				this.carousel.refs.MyCar1.carouselWrapperRef.focus();
			}
			if(this.refs.chasseBox){
				this.props.parent.refs.container.scrollTop = this.refs.chasseBox.getBoundingClientRect().top
			}
		}
	}

	componentDidMount(){

		var mesData = JSON.parse(localStorage.getItem("LOGIN_TOKEN"));
//		console.log("mesData : ",mesData.NoCli)
		if(!mesData) mesData = mData;


		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false });
			window.location.assign("/");
		}else{
			this.setState({ isLoged: true })
		}

		this.setState({isLoaded: false});
		axios.get("https://vod-dev.zone300.com/api/getAccueil_appli.php?action=peche")
		.then( res => {
			const resData = res.data;
			if(resData.ret){
				this.vCateg = resData.Categ;
				this.vFilm = resData.Film;
				this.setState({isLoaded: true});
				this.props.parent.state.navig.forceFocus(this.props.parent.state.Categ)

	
			}
		})
	}

	render(){

		return(
			<div ref="chasseBox" className={"mainbox" + (this.state.active ? ' focused' : '')} >
				{ this.state.isLoaded &&
					<VerticalList 
						ref={(el) => this.yourElement = el } 
						focusId="peche" 
						id="content"  
						onFocus={this.onFocusVertical} 
						onBlur={() => this.onBlurLists()}
					>
						<Focusable 
							onFocus={this.carouselOnFocus}
							onBlur={this.carouselOnBlur}
						>
							<Carousel 
								infiniteLoop={true}
								showThumbs={false}
								useKeyboardArrows={true}
								autoPlay={true}
								showStatus={false}
								ref={(elCar) => this.carousel = elCar}
								theme="peche" 
								className={(this.state.carouselActive ? " carousel-focus" : "") } 
							/>
						</Focusable	>
						{this.vCateg.map((item,i) =>
							<List 
								key={"idx"+item.id} 
								title={item.name} 
								films={this.vFilm["idx"+item.id]} 
								onFocus={() => this.changeFocusTo(i)} 
								visible={this.state.active !== null ? i >= this.state.active : true}
								viewport={this.props.parent.refs.container}
							/>
						)}
					</VerticalList>
				}
				{ !this.state.isLoaded &&
						<div style={{width:'100%',height:'100%',textAlign:'center',marginTop:'50vh'}}>
							<Spinner  color="#FBA00B" size={52} speed={0.7} animating={true} />
						</div>
				}

			</div>
		)
	}
}

export default Peche;