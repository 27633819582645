import React from 'react';
import axios from 'axios';

import { Focusable } from 'react-key-navigation'

import { Grid, VerticalList, HorizontalList } from 'react-key-navigation'

import './Search.css'

const mData = {
	is_Guest:'',
	NoCli:'',
}

class MyInput extends React.PureComponent{
	constructor(props){
		super(props);
		this.state = {
			isLoged: false,
			active: null,
			Categ: 'search',
			isLoaded: false,
		}
//		this.updateSearch = this.updateSearch.bind(this);
	}

	componentDidMount(){
		var maNav = this.state;
		window.sessionStorage.setItem("maNav", JSON.stringify(maNav));
	}
	async updateSearch(event) {
		const search = event.target.value;
		this.setState({ search : event.target.value });
//		console.log("State seach : ",search)
		var mesImages = [];
		var _self = this.props.parent;

		if(search.length >2 ){
			this.setState({isLoaded: true});

			var url = 'https://vod-dev.zone300.com/api/getSearch_v3b.php?action=search&q=' + search;

			await axios.get(url)
			.then( function(resp) {
				//var resultSearch = _self.renderItem(Object.values(resp.data.data));
				if(!resp.data.data.is_empty){
					Object.values(resp.data.data).map( (item) => {
						if( item ){
							var imgUrl = "https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + item.transaction + "-VISUEL.jpg&h=200&crop-to-fit";
							mesImages.push({uri : imgUrl, aff_titre: item.aff_titre, titre: item.titre,transaction: item.transaction, item: item});
						}
					})
				}
				_self.setState({images: mesImages, isLoaded:false});
			})
			.catch(function(err){
				_self.refs.maSearchBar.clear();
				_self.setState({images: [], isLoaded: false});
				console.log("Erreur : ",err)
			})

		}else{
//			console.log("state images : ".)
			_self.setState({images:[], isLoaded: false});
		}

	};


	onBlur() {
		this.setState({active: false});
	}
	onFocus() {
		this.setState({active: true});
	}
	render(){
		//console.log("MyInput props : ",this.props);
		return (
			<Focusable  
				onFocus={() => this.onFocus()} 
				onBlur={() => { this.onBlur(); this.nameInput.blur()}}  
				onEnterDown={(e,n) => {console.log("search render this : ", this); this.nameInput.focus()}}
			>
				<div className={this.state.active ? "myInputSearchFocus" : "myInputSearch"}>
					<div style={{width:'100%'}}>
						<i className="fa fa-search"></i>
						<input 
							autoComplete={'off'} 
							type={this.props.type}
							name={this.props.name}
							ref={(input) => { this.nameInput = input; }}
							//style={{width:'100%'}}
							onChange={ (text) => this.updateSearch(text) }
						/>
					</div>
				</div>
			</Focusable> 
		)
	}
}
class ToogleItem extends React.Component{
	constructor() {
		super()

		this.state = {
			active: false,
			isVisible: false,
		}
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.isInViewport = this.isInViewport.bind(this);

	}
	onEnterDown(event, navigation, item) {
		/*
		console.log('enter pressed');
		console.log("event : ", event);
		console.log("navigation : ", navigation );
		console.log("this.props.film ? ", item);
		*/
		window.location.assign('/detail/' + item.transaction);
		navigation.forceFocus('btnFocusableLecture');
	}

	isInViewport(offset = 0 ,viewport = null) {
		const viewp = (viewport) ? viewport.getBoundingClientRect().height : window.innerHeight;
		if (!this.yourElement) return false;
//		console.log("viewport.getBoundingClientRect().height : ", viewport.getBoundingClientRect().height);
//		console.log("this.yourElement.getBoundingClientRect() : ", this.yourElement.getBoundingClientRect() );
		const top = this.yourElement.getBoundingClientRect().top - viewport.getBoundingClientRect().top  ;
		const bottom = this.yourElement.getBoundingClientRect().bottom - viewport.getBoundingClientRect().top;
		let isVisible = true
		if(bottom > viewp){
			viewport.scrollTop += bottom - viewp;
			isVisible = true;
		}else{
			isVisible = (top + offset) >= 0 && (top - offset) <= viewp 
		}
//		console.log("isInViewPort top : ",top, " / bottom : ",bottom, " / viewp : ",viewp);
//		console.log("isInViewport : top : ",top," - offset : ",offset," - viewp : ",viewp, " (top + offset) >=0 && (top - offset) <= viewp : ",( (top + offset) >= 0 && (top - offset) <= viewp) );
		const ret = { 
			visible: isVisible ,
			direction : (top > 0 ) ? 'down' : 'up',
			obj : this.yourElement,
		}
		return ( ret );
	  }
	
	onFocus(){
		this.setState({active: true})

		if( !this.yourElement ) return false ;
//		console.log("Element focus : ", this.yourElement);

		const ret = this.isInViewport(0,this.props.viewport)
		if(!ret.visible){
//			console.log("trouve la direction : up / down");
//			console.log("this.props : ",this.props);
//			console.log("this.yourElement.offsetTop : ",this.yourElement.offsetTop);
			this.props.evtScroll(ret.obj,ret.direction,this.yourElement.offsetTop);
		}
//		console.log("parent element  ", this.props.num, " : ", this.yourElement.offsetTop );
		
	}
	onBlur(){
		this.setState({active: false})
	}
	render() {
//		console.log("Search ToggleItem  : ",this);
		const film = this.props.item;
		const affTitre = (film.aff_titre==='1') ? <div className="TextVignette">{film.titre}</div> : <div className="TextVignette" >&nbsp;</div>;

		return(
			<Focusable 
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onEnterDown={(e, n) => this.onEnterDown(e,n,film)}
			>
				<div 
					ref ={ (el) => { this.yourElement = el}}
					className={'item ' + (this.state.active ? 'item-focus' : '')}
					style={{backgroundSize:'cover',backgroundPosition:'center', backgroundImage: "url(https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + film.transaction + "-VISUEL.jpg&h=200&crop-to-fit"}}
					
				>
					{affTitre}
				</div>
			</Focusable>
		)
	}
}

export default class Search extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoged: false,
			active: null,
			Categ: 'user',
			isLoaded: false,
			uriVideo: '',
			images: [],
			search: '',
			thisContent : null,
			thisContentTop : 0,
		}
		this.evtScroll = this.evtScroll.bind(this)
	}



	onBlur() {
		this.setState({active: false});
	}

	onFocus() {
		this.setState({active: true});
	}

	
	componentDidMount(){

		//console.log("this.props.history : ",this.props.parent.props.history.replace({pathname: "/search"}));
		//window.history.replaceState(null, "New Page Title", "/search");

		if(this.content){
			this.setState({thisContent: this.content, thisContentTop: this.content.offsetTop});
		}

		var mesData = JSON.parse(localStorage.getItem("LOGIN_TOKEN"));
//		console.log("mesData : ",mesData.NoCli)
		if(!mesData) mesData = mData;


		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false });
			window.location.assign("/");
		}else{
			this.setState({ isLoged: true })
		}

//		console.log("this.props : ", this.props);
		this.setState({isLoaded: false});

		//		this.setState({isLoaded: true});
		setTimeout( () => this.props.parent.state.navig.forceFocus(this.props.parent.state.Categ), 100 );
		
	}

	evtScroll(obj,direction,item){
//1		if(this.state.thisContent){
		if(this.content){
				//			console.log("this.state.thisContent : ",this.state.thisContent);
//1			const top = obj.getBoundingClientRect().top - this.state.thisContent.getBoundingClientRect().top  ;
			const top = obj.getBoundingClientRect().top - this.content.getBoundingClientRect().top  ;
//			console.log("top : ", top);

			if( direction === 'up'){
//1				this.state.thisContent.scrollIntoView(true);
//1				this.state.thisContent.scrollTop = (item - this.state.thisContentTop);
				this.content.scrollIntoView(true);
				this.content.scrollTop = (item - this.state.thisContentTop);
			}
			if(direction === 'down'){
//				console.log("obj : ",obj.getBoundingClientRect())
//				console.log("this.state.thisContent.scrollTop : ",this.state.thisContent.scrollTop);
//				console.log( "this.state.thisContent.top + obj.getBoundingClientRect().height = ", this.state.thisContent.getBoundingClientRect().top )
// 
//1				this.state.thisContent.scrollIntoView(false);
//1				this.state.thisContent.scrollTop = (  item - this.state.thisContentTop  ); // - 175;
				this.content.scrollIntoView(false);
				this.content.scrollTop = (  item - this.state.thisContentTop  ); // - 175;
			}
//			console.log("item : ",item);
//			console.log("direction : ",direction);
//			console.log("obj : ",obj);
		}else{
			console.log("pas this.content");
		}
	}

	render() {
		//if( !this.state.images.length === 0 )console.log("this.container. top ", this.container.getBoundingClientRect().top)
//		console.log("container height : ", window.innerHeight - this.container.getBoundingClientRect().top)

		if(this.content && this.state.thisContentTop != this.content.offsetTop){
			this.setState({thisContentTop: this.content.offsetTop});
		}

		const numRow = (this.state.images.length > 0 ) ?( Math.ceil(this.state.images.length / 4) ) : 0;
		const contentheight = ( window.innerHeight - 20 ) - this.state.thisContentTop;
//		console.log("SEARCH /this : ",this.content.offsetTop)
		const contenu = (this.state.images.length > 0 ) ? <Grid id="gridSearch" className="cssGridSearch" ref={ (el) => this.grid = el} columns={4} rows={numRow}>
		{
			this.state.images.map( 
				(item,i) => {
//										console.log("state image : ", i)
//					return ( <ToogleItem viewport={this.state.thisContent} evtScroll={this.evtScroll} key={i} item={item} num={i+1} /> )
					return ( <ToogleItem viewport={this.content} evtScroll={this.evtScroll} key={i} item={item} num={i+1} /> )
				}
			)
		}
		</Grid>
		: null;

		console.log("this.grid : ", this.grid, " numRow : ", numRow) ;

		return (
			<div id="contentSearch" className={"mainbox" + (this.state.active ? ' focused' : '')} >
				<VerticalList focusId="search" onBlur={() => this.onBlur()}>
					<MyInput type="text" parent={this} />
					<hr />
					<div id="ctnSearch">
					{ this.state.images.length === 0 &&
						<div className="txtExplicatif">
						Tape un/des mots clefs correspondant aux film que tu recherches.<br />Tu peux rechercher directement le titre d'un film, un lieu, un type de gibier/poisson, une technique, un personnage...
						</div>
						||
						<div className="txtExplicatif">
							{ /* }
							<div>
							Nombre de vidéos trouvées : { this.state.images.length } <br />
							nb row : { (Math.ceil(this.state.images.length / 4) ) }
							</div>
					{ */ }

						</div>
					}
					</div>


				</VerticalList>
				<div className="ontainer" ref={(content) => { this.content = content}} style={{overflow:'auto', textAlign:'center', height: contentheight}} >
					{contenu}
				</div>

			</div>
		);
	}
}
