import React from 'react';

import { Focusable, HorizontalList } from 'react-key-navigation';
import "./List.new.css";

class ToogleItem extends React.Component {
	constructor() {
		super();

		this.state = {
			active: false
		}
		this.itemWidth = 0;

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onEnterDown = this.onEnterDown.bind(this);
		this.isInViewport = this.isInViewport.bind(this);

	}
	isInViewport(offset = 0 ,viewport = null) {
		if(viewport ){
			viewport = viewport.getElementsByClassName("hz-list")[0];
		}

		const viewp = (viewport) ? viewport.getBoundingClientRect().width : window.innerWidth;
		if (!this.yourElement) return false;
		const left = this.yourElement.getBoundingClientRect().left - viewport.getBoundingClientRect().left;
		const right = this.yourElement.getBoundingClientRect().right - viewport.getBoundingClientRect().left;
		let isVisible = true

//		console.log("viewport.scrollLeft : ", viewport.scrollLeft );		
//		console.log("left : ", left, " / right : ", right, ' / viewp : ',viewp);


		if(right > viewp){
//			console.log("boom => Right ", viewport.scrollLeft)
			viewport.scrollLeft += right - viewp;
			isVisible = true;
		}else if (left < 0){
			viewport.scrollLeft += left;
		}else{
			isVisible = (left + offset) >= 0 && (left - offset) <= viewp 
		}


	}

	onBlur(){
		this.setState({active: false});
	}
	onFocus(){
		this.setState({active: true});
		const ret = this.isInViewport(0,this.props.viewport)

	}
	onEnterDown(event, navigation, item) {
/*
		console.log('enter pressed');
		console.log("event : ", event);
		console.log("navigation : ", navigation );
		console.log("this.props.film ? ", item);
*/
		window.location.assign('/detail/' + item.transaction);
		navigation.forceFocus('btnFocusableLecture');

//		navigation.forceFocus('sidebar');
	}
	
	render() {

		const affTitre = (this.props.film.aff_titre==='1') ? <div className="TextVignette">{this.props.film.titre}</div> : <div className="TextVignette" >&nbsp;</div>;
		return (
			<Focusable 
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onEnterDown={(e, n) => this.onEnterDown(e,n,this.props.film)}
			>
				<div 
					ref ={ (el) => { this.yourElement = el}}
					className={'item ' + (this.state.active ? 'item-focus' : '')}
					style={{backgroundSize:'cover',backgroundPosition:'center', backgroundImage: "url(https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + this.props.film.transaction + "-VISUEL.jpg&h=200&crop-to-fit"}}
					
				>
					{affTitre}
				</div>
			</Focusable>
		);
	}
};

export default class List extends React.Component {
  constructor() {
    super();
    this._lastFocus = null;
  }

	componentDidMount() {
		/*
		const width = (Math.floor(this.content.scrollWidth /  this.content.clientWidth ) * this.content.clientWidth) + this.content.clientWidth + 20;

		if (this.content.getElementsByClassName('hz-list')[0]) {
			this.content.getElementsByClassName('hz-list')[0].style.width = width + 'px';
		}
		*/
	}

	onFocus(index,evt) {
		if (this._lastFocus === index) {
			return;
		}
		if (this.props.onFocus) {
			//this.props.onFocus();
		}
		const viewp = (this.props.viewport) ? this.props.viewport.getBoundingClientRect().height : window.innerHeight;

		const top = this.yourElement.getBoundingClientRect().top - this.props.viewport.getBoundingClientRect().top  ;
		const bottom = this.yourElement.getBoundingClientRect().bottom - this.props.viewport.getBoundingClientRect().top;
		let isVisible = true
//		console.log(" element => top : ", top, " Bottom : ",bottom, "viewp : ",viewp );
		if(bottom > viewp){
			this.props.viewport.scrollTop += bottom - viewp;
			isVisible = true;
		}else if (top < 0 ){
			this.props.viewport.scrollTop += top;
		}else{
			isVisible = (top ) >= 0 && (top ) <= viewp 
		}
//		console.log("this props viewport : ", this.props.viewport);
		/*
		if (this.content) {
			const items = this.content.getElementsByClassName('item');
			const offsetWidth = items[0].offsetWidth + 20;
			this.content.scrollLeft = offsetWidth * index;
		}
		*/

		this._lastFocus = index;
	}


	render() {
/*
		if(this.content){ 
			const width = (Math.floor(this.content.scrollWidth /  this.content.clientWidth ) * this.content.clientWidth) + this.content.clientWidth + 20;
			console.log("width : ", width);
		}
*/
		return (
			<div ref={(el) => this.yourElement = el} className={"contentgroup " /* + (this.props.visible ? '' : 'fading-out') */}>
				<h1>{this.props.title}</h1>
				<div className="content" ref={(content) => { this.content = content}} >
					<HorizontalList className="hz-list"
									style={{overflow: 'hidden', display: 'block'}}
									onFocus={(index,evt) => this.onFocus(index,evt)}
									onBlur={() => { this._lastFocus = null }}
									retainLastFocus={true}
					>
						{this.props.films.map((item,i) =>
							<ToogleItem viewport={this.content} key={i} film={item} />
						)}
					</HorizontalList>
				</div>
			</div>
		);
	}
}
