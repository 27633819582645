import React from 'react';

import { Focusable, VerticalList } from 'react-key-navigation';

import axios from 'axios';
import constants from '../../constants';

import ReactHtmlParser from 'react-html-parser';

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';



import "./Detail.css";

class BtnRetour extends React.Component{
	constructor(props){
		super(props);

		this.state = {
			active: false,
			isLoaded: false,
		}
	}

	render(){
		return(
			<Focusable
				onFocus={() => this.setState({active: true})}
				onBlur={() => this.setState({active: false})}
				onEnterDown={(e, n,s) => {console.log("return : ",this.props.parent); this.props.parent.props.history.goBack(); }}
			>
				<div className={'fa fa-arrow-left btnRetour' + (this.state.active ? ' btnLecture-focus' : '')}> 
					
				</div>
			</Focusable>
		)
	}

}

class BtnLecture extends React.Component{
	constructor(props){
		super(props);

		this.state = {
			active: false
		}
	}

	onEnterDown(e,navigation,film){
		window.location.assign("/lecture/" + film.transaction)
	}
	render(){

		return(
			<Focusable
				defaultNav
				onFocus={() => this.setState({active: true})}
				onBlur={() => this.setState({active: false})}
				onEnterDown={(e, n) => this.onEnterDown(e,n,this.props.film)}
				focusId="btnFocusableLecture"
			>
				<div id="btnLecture" className={'btnLecture' + (this.state.active ? ' btnLecture-focus' : '')}> 
					Lecture
				</div>
			</Focusable>
		)
	}
}

class Details extends React.Component{
	constructor (props){
		super(props);

		this.state = {
			isLoaded: false,
			img_isLoaded: false,
			film : [],
			synopsis: '',
			img: null,
		};
		this.myOnLoad = this.myOnLoad.bind(this);
	}

	componentDidMount(){
		// Charge le film
		axios.get("https://vod-dev.zone300.com/api/appli" + constants.V_SERVEUR + "/getFilm.php?transaction=" + this.props.match.params.FilmID )
		.then( res => {
			if(res.data.ret){
				this.setState({ film:  res.data.data, isLoaded:true, synopsis: res.data.data.synopsis });
				localStorage.setItem("Film", this.state.film.vimeo_hls);
				localStorage.setItem("FilmVideo", this.state.film.vimeo_VideoId);
				localStorage.setItem("FilmHls", this.state.film.vimeo_hls);
//				localStorage.setItem("FilmVideo", this.state.film.vimeo_VideoId);
	/*
				axios.get ("https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + this.props.match.params.FilmID + "-VISUEL2.jpg&w="+ window.innerWidth+ "&h=" + window.innerHeight + "&crop-to-fit")
				.then ( res1 => {
					//console.log("res1 : ", res1.data)
				})
	*/
			}else{
				window.location.back();
			}
			//console.log("res : ",res);
		});
	}

	myOnLoad(evt){
		//console.log("myOnLoad : ",evt);
		this.setState({img_isLoaded:true});
	}
	render(){
//		console.log("this.props.match : ",this.props.match);
//		console.log("this.state : ",this.state );
		if(this.props.match.params.FilmID===''){
			window.location.back();
		}
		const myUri = "https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + this.props.match.params.FilmID + "-VISUEL2.jpg&w="+ window.innerWidth+ "&h=" + window.innerHeight + "&crop-to-fit";
		//const myImg = <img src={myUri} onLoad={this.myOnLoad} />
		//this.Navigation.forceFocus('mainDetail')
		return (
			<div id="mainDetail" >
				<VerticalList>
					<div className="divBackground">
					{ !this.state.img_isLoaded &&
						<div style={{width:'100%',height:'100%',textAlign:'center',marginTop:'50vh'}}>
							<Spinner  color="#FBA00B" size={52} speed={0.7} animating={true} />
						</div>
					}
						<img src={myUri} onLoad={this.myOnLoad} />
					</div>
					<div className="gradient">
						<BtnRetour parent={this} />
						<div className="DescFilm">
							<div className="titre">{this.state.film.titre}</div>
							<BtnLecture film={this.state.film}/>
							<div className="DescContent">
								{ ReactHtmlParser(this.state.synopsis) }
							</div>
						</div>
					</div>
				</VerticalList>
			</div>

		)
	}
}
export default Details;
