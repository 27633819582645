
import React from 'react';

import List from './List.new';
import axios from 'axios';
import constants from '../../constants';

import VideoPlayer from '@cybergab/gab-react-video-js-player';
import { VerticalList, Focusable } from 'react-key-navigation'

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import Coverflow from '@cybergab/gab-react-coverflow';
 
import './Zone300TV.css';
import './Chasse.css';

const mData = {
	is_Guest:'',
	NoCli:'',
}

const heightCar = Math.round( window.innerHeight / 2.30);


class MonZ3 extends React.Component {
	constructor(){
		super()
		this.state = {
			isLoged: false,
			active: null,
			Categ: 'monZ3',
			isLoaded: false,
		}
		this.carImg = [];
		this.vCateg = [];
		this.vFilm = [];
//		this.lists = ["Title 1", "Title 2", "Title 3", "Title 4"];
		this.carouselOnBlur = this.carouselOnBlur.bind(this)
		this.carouselOnFocus = this.carouselOnFocus.bind(this)
		this.carouselOnKeyDown = this.carouselOnKeyDown.bind(this)
		this.changeFocusTo = this.changeFocusTo.bind(this);
		this.onFocusVertical = this.onFocusVertical.bind(this);

	}
	onFocusVertical(index,item, ess){
		//console.log("onFocusVertical => index : ",index," item : ",item, " ess : ", ess)
	}
	changeFocusTo(index) {
		this.setState({active: index});
	  }
	
	onBlurLists() {
		this.setState({active: null});
	}

	carouselOnBlur(){
		this.setState({carouselActive: false});
		this.elCar.refs.figure_0.firstChild.blur();
//		console.log("onblur carousel this.elCar : ");

	}
	carouselOnFocus(){
		this.setState({carouselActive: true});
		if(this.refs.monz3Box){
			this.props.parent.refs.container.scrollTop = this.refs.monz3Box.getBoundingClientRect().top
		}
//		console.log("this.elCar : ",this.elCar);
		if(this.elCar){
			this.elCar.refs.figure_0.firstChild.focus();
			this.elCar.refs['figure_' + this.elCar.state.current].classList.add('itemFocus');
		}
	}
	carouselOnKeyDown(evt,obj){
//		console.log("carousel on keyDown : evt = ",evt.keyCode, " / obj = ",obj);
		if(evt.keyCode === 39 || evt.keyCode === 37 ){
			evt.preventDefault();
			evt.stopPropagation();
		}
		if(evt.keyCode === 40){
			this.elCar.refs['figure_' + this.elCar.state.current].classList.remove('itemFocus');
			return false;
		}
		if(evt.keyCode === 13){
			/*
			console.log("obj.state.current : ", obj.state.current);
			console.log("this.carImg : ",this.carImg);
			*/
			window.location.assign('/detail/' + this.carImg[obj.state.current].transaction);
			
		}
		this.elCar.refs['figure_' + this.elCar.state.current].classList.remove('itemFocus');
		setTimeout( () =>{ 
			this.elCar.refs['figure_' + this.elCar.state.current].classList.add('itemFocus');
		},10);
	}

	componentDidMount(){

		var mesData = JSON.parse(localStorage.getItem("LOGIN_TOKEN"));
		if(!mesData) mesData = mData;


		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false });
			window.location.assign("/");
		}else{
			this.setState({ isLoged: true })
		}

		this.setState({isLoaded: false});
		axios.get("https://vod-dev.zone300.com/api/appli" + constants.V_SERVEUR + "/get_Personnalisation.php?NoCli="  + mesData.NoCli)
		.then( res => {
			if(res.data.ret) {
				this.vCateg = res.data.data.categ;
				this.vFilm = res.data.data.films;
				this.setState({isLoaded:true});
				if(this.props.parent.state.navig) this.props.parent.state.navig.forceFocus(this.props.parent.state.Categ)

			}
		});
		// Charge le carousel
		axios.get("https://vod-dev.zone300.com/api/appli" + constants.V_SERVEUR + "/getCarousel.php?Categ=" + this.state.Categ)
		.then( res => {
			if(res.data.ret) {
				this.carImg = res.data.data;
			}
		})

	}
	render(){
		return(

			<div ref="monz3Box" className={"mainbox" + (this.state.active ? ' focused' : '')} >
				{ this.state.isLoaded &&
					<VerticalList 
						ref={(el) => this.yourElement = el } 
						focusId="monZ3" 
						id="content" 
						onFocus={this.onFocusVertical} 
						onBlur={() => this.onBlurLists()}
					>
						<Focusable 
							onFocus={this.carouselOnFocus}
							onBlur={this.carouselOnBlur}
						>
								<Coverflow
									ref={(el)=>{this.elCar = el}}
									width={960}
									height={heightCar}
									infiniteScroll
									enableScroll={false}
									displayQuantityOfSide={2}
									navigation
									enableHeading={false}
									currentFigureScale={2}
									onKeyDown={this.carouselOnKeyDown}
								>
									{this.carImg.map( (item,i) => 
									<div role="menuitem" tabIndex={i} 
										//onFocus={(foc)=> console.log("onFocus_0 : ",foc)} 
										//onBlur={(blu)=> console.log("onBlur_0 : ",blu)} 
									>
										<img 
											src={"https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + item.transaction + "-VISUEL.jpg&h=" + heightCar + "&crop-to-fit"} 
											alt='' 
											style={{ display: 'block', width: '100%' }}
										/>
									</div>
									) }
								</Coverflow>
						</Focusable>

						{this.vCateg.map((item,i) =>
							<List 
								key={"idx"+item.id} 
								title={item.name} 
								films={this.vFilm["idx"+item.id]} 
								onFocus={() => this.changeFocusTo(i)} 
								visible={this.state.active !== null ? i >= this.state.active : true}
								viewport={this.props.parent.refs.container}
							/>
						)}

					</VerticalList>
				}
				{ !this.state.isLoaded &&
					<div style={{width:'100%',height:'100%',textAlign:'center',marginTop:'50vh'}}>
						<Spinner  color="#FBA00B" size={52} speed={0.7} animating={true} />
					</div>
				}

			</div>
		)
	}
}

export default MonZ3;