import React from 'react';

import {
	BrowserRouter as Router,
	Route,
	Switch
} from "react-router-dom";

//import Navigation from 'react-key-navigation';


import Landing from './LandingPage';
import Connexion from './Connexion';
import Zone300TV from '../browse';
//import ForgotPassword from './ForgotPassword';
//import Inscription from './Inscription';
//import AppContainer from '../NavHomeScreen';

/*
class Accueil extends React.Component { 
	render(){ 
		return( <AppContainer />) 
	}
};
*/

class Nologged extends React.PureComponent{
	render(){
		return(
				<Router>
					<Switch>
						<Route path="/" exact component={Landing} />
						<Route path="/connexion/" component={Connexion} />
						<Route path="/browse/" component={Zone300TV} />
					</Switch>
				</Router>
		);
	} 
}
export default Nologged ;